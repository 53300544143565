import moment from 'moment';

export const isEmpty = (value) => {
	return value == null || value === '' || value === undefined;
};

export const formatViewDetailsDate = (date) => {
	return moment(date).format('MMM Do YYYY');
};

export const formatViewDetailsDateTime = (date) => {
	return moment(date).format('HH:mm:ss A');
};

export const formatViewDetailsDateShow = (date) => {
	return moment(date).format('D MMM, YYYY HH:mm A');
};

export const formatViewDetailsDateShowTime = (date) => {
	return moment(date).format('MMM Do, YYYY HH:mm:ss A');
};

export const formatViewDetailsMonthYear = (date) => {
	return moment(date).format('MMM YYYY');
};

export const formatTimeline = (date) => {
	let time;

	// Check if date is today
	if (moment(date).isSame(moment(), 'day')) {
		time = 'Today';
	} else if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
		time = 'Yesterday';
	} else {
		time = moment(date).format('MMM Do, YYYY');
	}

	return time;
};

export const money = (value) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	}).format(value);
};

export function exportCSV(csvHeader, csvData, prefix = 'export', footerRow = []) {
	const dataArray = [csvHeader, ...csvData, footerRow];
	const csvString = dataArray.map((row) => row.join(',')).join('\n');
	const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);

	let dateTimeString = moment().format('YYYY-MM-DD-HHmmss');
	link.download = `${prefix}-${dateTimeString}.csv`;
	link.click();
}

export function toCurrencyFormat(value) {
	if (!value && value !== 0) return 'N/A';
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0
	}).format(value);
}

export function formatNumericVariable(variable) {
	if (
		(typeof variable === 'number' && !isNaN(variable)) ||
		(typeof variable === 'string' && /^\d+$/.test(variable))
	) {
		return Number(variable).toLocaleString(); // Formats the number with comma separators
	} else {
		return variable;
	}
}

export function isNumeric(variable) {
	return (
		!isNaN(variable) ||
		(typeof variable === 'number' && !isNaN(variable)) ||
		(typeof variable === 'string' && /^\d+$/.test(variable))
	);
}

export function isIterable(variable) {
	return Symbol.iterator in Object(variable);
}

export const generateUniqueString = () => {
	return Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
};

//  Preserve line breaks
export const nl2br = (str) => {
	return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export function truncateString(string, length = 230) {
	if (string.length <= length) {
		return string;
	}
	const truncatedString = string.substring(0, length);
	const lastCharacters = truncatedString.slice(-20);
	const cleanLastCharacters = lastCharacters.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags from the truncated string

	return truncatedString.substring(20) + cleanLastCharacters + '...';
}

export function sortList(list, key) {
	list.sort((a, b) => {
		if (a[key] < b[key]) {
			return -1;
		}
		if (a[key] > b[key]) {
			return 1;
		}
		return 0;
	});
}

export function getImageDimensions(url) {
	return new Promise((resolve) => {
		let img = new Image();
		img.onload = () => resolve({ width: img.width, height: img.height });
		img.onerror = resolve({ width: 0, height: 0 });
		img.src = url;
	});
}

export function debounce(func, wait = 500) {
	let timeout;

	return function () {
		const context = this,
			args = arguments;
		const later = function () {
			timeout = null;
			func.apply(context, args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}
